export default {
  state: {
    isCollapse: false,
    selectMenuList: [
      {
        path: "/",
        name: "home",
        label: "首页",
      },
    ],
  },
  mutations: {
    collapseMunu(state) {
      state.isCollapse = !state.isCollapse;
    },
    // 添加面包屑
    addSelectMenu(state, val) {
      if (val.name !== "home") {
        const result = state.selectMenuList.findIndex(
          (item) => item.path === val.path
        );
        if (result === -1) {
          state.selectMenuList.push(val);
        }
      }
    },
    // 删除面包屑
    removeSelectMenu(state, tag) {
      const index = state.selectMenuList.findIndex(
        (item) => item.name === tag.name
      );
      if (index !== -1) {
        state.selectMenuList.splice(index, 1);
      }
    },
  },
};
