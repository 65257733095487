import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/",
        name: "homeMain",
        component: () => import("../views/home"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home"),
      },
      {
        path: "/company",
        name: "company",
        component: () => import("../views/company"),
      },
      {
        path: "/business",
        name: "business",
        component: () => import("../views/business"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/product"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/order"),
      },
      {
        path: "/propertywater",
        name: "propertywater",
        component: () => import("../views/propertywater"),
      },
      {
        path: "/popularize",
        name: "popularize",
        component: () => import("../views/popularize"),
      },
      {
        path: "/intention",
        name: "intention",
        component: () => import("../views/intention"),
      },
      {
        path: "/menu",
        name: "menu",
        component: () => import("../views/menu"),
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/role"),
      },
      {
        path: "/system",
        name: "system",
        component: () => import("../views/system"),
      },
      {
        path: "/tax",
        name: "tax",
        component: () => import("../views/tax"),
      },
      {
        path: "/vehicle",
        name: "vehicle",
        component: () => import("../views/vehicle"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
